import React, { useState } from 'react'
import { isNumber, isRequired, isValidRsaIdNumber, isValidEmail } from '../../../lib/validators'
import { WizardModal, CompleteBlob, SendDocumentModal, TermsAndConditionsLink } from '../../shared'
import { ActivityIndicator } from '../../generic'
import gql from 'graphql-tag'
import { navigate } from 'gatsby-link'
import { sittingOnMoney } from '../../../images'
import { useApolloClient } from '@apollo/react-hooks'
import firebase from 'gatsby-plugin-firebase'

const GET_CREDIT_REPORT = gql`query($idNumber:String!, $income: Float) {
  getChallenge(idNumber: $idNumber, income: $income) {
    error
    telephones
    employers
    accounts
  }
}`

const GET_CREDIT_REPORT_ACCESS = gql`query($idNumber: String!, $telephone: String, $employer: String, $account: String) {
  doChallenge(idNumber: $idNumber, telephone: $telephone, employer: $employer, account: $account)   {
    passedChallenge
    accessCode
  }
}`

const EMAIL_CREDIT_REPORT = gql`query($idNumber: String!, $email: String, $accessCode: String) {
  emailCreditReport(idNumber: $idNumber, email: $email, accessCode: $accessCode)
}`

const buildSteps = (setOpen, setIdNumber, setQuestionData, setAccessCode, setEmailModal) => {
  let accessCode

  return [{
    name: "step1",
    beforeStep: () => {
      firebase.analytics().logEvent("CREDIT_REPORT_REQUEST_START");
    },
    title: "Credit Report",
    title2: "Please enter your South African ID so we can pull your credit report",
    fieldNames: ["idNumber", "acceptTerms"],
    nextTitle: "Next",
    nextSteps: ["income"]
  }, {
    name: "income",
    title: "Credit Report - Affordability",
    beforeStep: () => {
      firebase.analytics().logEvent("CREDIT_REPORT_REQUEST_INCOME");
    },
    title2: "Please confirm your income so we can calculate your affordability",
    fieldNames: ["income"],
    nextSteps: ["error"],
    nextTitle: "Get Free Credit Report",
  }, {
    name: "verificationStep1",
    beforeStep: async ({ apolloClient, chatState }) => {
      firebase.analytics().logEvent("CREDIT_REPORT_REQUEST_VERIFICATION_START");
      const { idNumber, income } = chatState
      setIdNumber(idNumber)
      const result = await apolloClient.query({ query: GET_CREDIT_REPORT, variables: { idNumber, income } })
      setQuestionData(result.data.getChallenge)
    },
    title: "Credit Report - Challenge Questions",
    title2: "Please answer the following questions to validate your identity",
    fieldNames: ["telephone"],
    nextSteps: ["verificationStep2"]
  }, {
    name: "verificationStep2",
    title: "Credit Report - Challenge Questions",
    title2: "Please answer the following questions to validate your identity",
    fieldNames: ["employer"],
    nextSteps: ["verificationStep3"]
  },
  {
    name: "verificationStep3",
    title: "Credit Report - Challenge Questions",
    title2: "Please answer the following questions to validate your identity",
    fieldNames: ["account"],
    nextSteps: ["performVerification"]
  },
  {
    name: "performVerification",
    beforeStep: async ({ apolloClient, chatState }) => {
      const { idNumber, telephone, employer, account } = chatState
      const result = await apolloClient.query({ query: GET_CREDIT_REPORT_ACCESS, variables: { idNumber, telephone, employer, account } })
  
      console.log(result)

      if (result.data.doChallenge && result.data.doChallenge.passedChallenge) {
        accessCode = result.data.doChallenge.accessCode
      } else {
        accessCode = null
      }

      if (accessCode) {
        firebase.analytics().logEvent("CREDIT_REPORT_REQUEST_VERIFICATION_SUCCESS");
      } else {
        firebase.analytics().logEvent("CREDIT_REPORT_REQUEST_VERIFICATION_ERROR");
      }
      setAccessCode(accessCode)
    },
    component: ActivityIndicator,
    nextAfterDelay: 1000,
    noNext: true,
    nextSteps: () => accessCode ? ["complete"] : ["error"]
  },
  {
    name: "complete",
    title: "Credit Report",
    title2: <CompleteBlob image={sittingOnMoney} title="You're all set!" description="Your Credit Report is ready!" />,
    nextButtonsInColumn: true,
    nextButtons: [{ label: "View online", nextSteps: ["done"] } /*, { label: "Email My report", onClick: () => { firebase.analytics().logEvent("CREDIT_REPORT_REQUEST_EMAIL_REPORT"); setEmailModal(true) }, noNextStep: true }*/]
  },
  {
    name: "error",
    title: "Credit Report",
    title2: <CompleteBlob image={sittingOnMoney} title="Oops!" description="Something went wrong." />,
    nextButtonsInColumn: true,
    nextButtons: [{ label: "Let's try again", nextSteps: ["step1"] }, { label: "Nevermind, I'm out", onClick: () => setOpen(false), noNextStep: true }]
  },
  {
    name: "done",
    beforeStep: () => {
      firebase.analytics().logEvent("CREDIT_REPORT_REQUEST_VIEW_REPORT");
      setOpen(false)
      navigate(`/credit-report/${accessCode}`)
    } 
  }] 
} 

const fields = {
  idNumber: {
    type: "text",
    label: "Your ID Number",
    placeholder: "Enter 13 digit ID number",
    validate: [isValidRsaIdNumber, isRequired]
  },
  telephone: {
    label: "What is your telephone number?",
    type: "radiogroup",
    options: [],
    validate: [isRequired]
  },
  income: {
    label: "What is your net-income?",
    type: "currency",
    validate: [isRequired, isNumber],
  },
  employer: {
    label: "Who is your employer?",
    type: "radiogroup",
    options: [],
    validate: [isRequired]
  },
  account: {
    label: "Who do you have an account with?",
    type: "radiogroup",
    options: [],
    validate: [isRequired]
  },
  acceptTerms: {
    type: "checkbox",
    label: <>Accept our <TermsAndConditionsLink>Terms of Services and Privacy Policy</TermsAndConditionsLink></>,
    validate: value => value === true ? undefined : "You need to accept terms and conditions to continue"
  }
}

export const emailCreditReport = (apolloClient, variables) => {
  apolloClient.query({ query: EMAIL_CREDIT_REPORT, variables })
}

function GetCreditReport({ open, setOpen, idNumber, setIdNumber }) {
  const [emailModal, setEmailModal] = useState(false)
  const [accessCode, setAccessCode] = useState(null)
  const apolloClient = useApolloClient()

  const setQuestionData = ({ telephones, accounts, employers }) => {
    fields.telephone.options = telephones
    fields.account.options = accounts
    fields.employer.options = employers
  }

  return <>
    <SendDocumentModal title="Email My Credit Report" label="Enter your email" document="Credit Report" open={emailModal} setOpen={setEmailModal} validate={[isRequired, isValidEmail]} onSend={email => emailCreditReport(apolloClient, { email, idNumber, accessCode })} zIndex={1001} />
    <WizardModal
      name="creditReport"
      open={open}
      setOpen={setOpen}
      steps={buildSteps(setOpen, setIdNumber, setQuestionData, setAccessCode, setEmailModal)}
      initialValues={{ idNumber, acceptTerms: true }}
      fields={fields}
    />
  </>
}

export default GetCreditReport
