import React, { useEffect, useState } from 'react'
import { GetCreditReport, MyCreditReport } from '../components/credit-report'
import { Router } from '@reach/router'
import PrivateLayout from '../components/PrivateLayout'
import { ActivityIndicator } from '../components/generic'
import SEO from '../components/seo'

// TODO: Add a check for client

function CreditReport() {
  const [open, setOpen] = useState(true)
  const [idNumber, setIdNumber] = useState()

  useEffect(() => {
    let givenIdNumber = new URLSearchParams(window.location.search).get('idNumber')
    setIdNumber(givenIdNumber)
  }, [])

  if (typeof window === "undefined") {
    return <div />
  }

  return <PrivateLayout showSideBar={false} selectedIndex={0}>
    <SEO title="MyMeerkat Credit Report" />
    <Router basepath="/credit-report">
      <GetCreditReport path="/" open={open} setOpen={setOpen} idNumber={idNumber} setIdNumber={setIdNumber} />
      <MyCreditReport path="/:id" idNumber={idNumber} />
    </Router>
  </PrivateLayout>
}

export default CreditReport