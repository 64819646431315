import React, { useState } from 'react'
import { MyCreditReportContainer } from './MyCreditReport.style'
import { useQuery, Query, useApolloClient } from 'react-apollo'
import { ActivityIndicator, Text, Button, Slider, PieGraph } from '../../generic'
import gql from 'graphql-tag'
import { MokuNotePanel, GetInfoCard, SendDocumentModal, CompleteBlob } from '../../shared'
import { printCurrency } from '../../../lib/utils'
import { SummaryItemsRow, OpenOptionContainer } from '../../dashboard/MyDashboard/MyDashboard.style'
import { getMaxDate } from '../../dashboard/MyDashboard/MyDashboard'
import SummaryItemCard from '../../dashboard/SummaryItemCard'
import InformationRow from '../../dashboard/InformationRow'
import ToggleProductOption from '../../dashboard/ToggleProductOption'
import ContactModal from '../../ContactModal'
import { savings, creditLife, funeral } from '../../../images/productInfo'
import { getRiskProfile, getAffordabilitySummary } from '../../../lib/financial'
import { CardRow, Card, GraphContainer } from '../../financialHealth/MyFinancialHealth/MyFinancialHealth.style'
import { navigate } from 'gatsby'
import { isRequired, isValidEmail } from '../../../lib/validators'
import { emailCreditReport } from '../GetCreditReport/GetCreditReport'
import { sittingOnMoney } from '../../../images'
import CreditWorthinessFactors from '../../shared/CreditWorthinessFactors/CreditWorthinessFactors'
import FinancialHealthGraphs from '../../shared/FinancialHealthGraphs'
import { RowSpacer } from '../../generic/Layouts';

const GET_CREDIT_REPORT = gql`query ($idNumber: String!, $accessCode: String) {
  getCreditRecord(idNumber: $idNumber, accessCode: $accessCode) {
    allDebt
    housingDebt
    vehicleDebt
    normalDebt
    compuScore
    affordability  
    totalInterest
    income
    debtPaymentProjection {
      date
      payment
    }
    withDCPaymentProjection {
      payment
      payments {
        date
        payment
      }
      interestSaved
    }
    accessCode
  }
}
`

const MY_SNOWBALL_PROJECTION = gql`
  query($amount: Float, $withDC: Boolean) {
    getSnowballProjection(amount: $amount, withDC: $withDC) {
      payment
      payments {
        date
        payment
      }
      interestSaved
    }
  }
`

const MY_FINANCIAL_HEALTH = gql`{
  myClient {
    id
    income
    firstNames
    debtBreakdown {
      allDebt
      housingDebt
      vehicleDebt
      normalDebt
      compuScore
      affordability
      debtPaymentProjection {
        date
        payment
      }
      withDCPaymentProjection {
        payment
        payments {
          date
          payment
        }
        interestSaved
      }
      estimatedIncome
      hasDebtCounselling
      budget {
        description
        amount
      }
    }
    savings {
      id
      bucketInfo {
        amount
        targetAmount
      }
    }
  }
}`


const getMokuTipProps = ({ affordability, compuScore }) => {
  if (compuScore > 620 && affordability > 0) {
    return {
      title: "Moku Tip: Credit Worthiness is Looking Good!",
      description: "Your credit score and ability to keep up with your credit commitments are both pretty good!",
      color: "primary"
    }
  }
  if (compuScore > 620 && affordability <= 0) {
    return {
      title: "Moku Tip: Let’s relook your budget!",
      description: "Your credit score is pretty good Well done. However, your debt capacity to pay your debts is low.  We recommend you review your budget to see if you can reduce your current expenses.",
      color: "alertAmber"
    }
  }
  if (compuScore <= 620 && affordability > 0) {
    return {
      title: "Moku Tip: Let’s work on your profile",
      description: " Your credit rating is low, but your debt capacity is high.",
      color: "alertAmber"
    }
  }
  if (compuScore <= 620 && affordability <= 0) {
    return {
      title: "Moku Tip: Let’s improve your profile!",
      description: "Your profile is not looking promising you might struggle to get credit. We can help improve this through our debt management solution.",
      color: "alertRed"
    }
  }
}


// const mockData = {
//   data: {"getCreditRecord":{"allDebt":0,"housingDebt":0,"vehicleDebt":null,"normalDebt":0,"compuScore":654,"affordability":37057.12,"totalInterest":0,"income":45000,"debtPaymentProjection":[],"withDCPaymentProjection":{"payment":0,"payments":[],"interestSaved":0,"__typename":"DebtManagementProjection"},"accessCode":"387d8b9a-f79c-4891-bb8c-4b67ee838497","__typename":"DebtBreakdown"}},
//   loading: false,
//   error: false,
// };

function MyCreditReport({ id, idNumber }) {
  const { data, loading, error } = useQuery(GET_CREDIT_REPORT, { variables:  { accessCode: id, idNumber} })
  //const { data, loading, error } = mockData;
  const { data:cData, loading:cLoading, error:cError } = useQuery(MY_FINANCIAL_HEALTH);
  const apolloClient = useApolloClient()
  const [withDC, setWithDC] = useState(false)
  const [withSnowball, setWithSnowball] = useState(false)
  const [snowballAmount, setSnowballAmount] = useState(150)
  const [contactModalOpen, setContactModalOpen] = useState(false)
  const [snowballProjection, setSnowballProjection] = useState()
  const [emailModal, setEmailModal] = useState(false)

  if (loading || cLoading) {
    return <ActivityIndicator />
  }

  if (error || cError) {
    console.log(error)
    return <center>
      <CompleteBlob image={sittingOnMoney} title="Error!" description="This link has already been used - please sign in or register to access your credit report" />
      <p />
      <p />
      <Button onClick={() => navigate('/login')}>Sign In</Button>
      <p />
      <Button onClick={() => navigate('/sign-in')} inverted>Register</Button>
    </center>
  }

  console.log("The id is", idNumber, id)

  const useData = {
    ...((data && data.getCreditRecord) || {}),
    debtBreakdown: {
      home: (data && data.getCreditRecord && data.getCreditRecord.housingDebt)|| 0,
      education: 0,
      personal: (data && data.getCreditRecord && data.getCreditRecord.normalDebt) || 0,
      car:  (data && data.getCreditRecord && data.getCreditRecord.vehicleDebt) || 0,
      credit: 0
    }
  }

  const financialHealthData = {
    ...((cData && cData.myClient && cData.myClient.debtBreakdown) || {}),
    income: cData && cData.myClient.income,
    debtBreakdown: {
      home: (cData && cData.myClient && cData.myClient.debtBreakdown.housingDebt)|| 0,
      education: 0,
      personal: (cData && cData.myClient && cData.myClient.debtBreakdown.normalDebt) || 0,
      car:  (cData && cData.myClient && cData.myClient.debtBreakdown.vehicleDebt) || 0,
      credit: 4800
    }
  }

  const riskSummmary = getRiskProfile(useData)
  const affordabilitySummary = getAffordabilitySummary(useData)

  return <MyCreditReportContainer>
    <SendDocumentModal title="Email My Credit Report" label="Enter your email" document="Credit Report" open={emailModal} setOpen={setEmailModal} validate={[isRequired, isValidEmail]} onSend={email => emailCreditReport(apolloClient, { accessCode: useData.accessCode, email, idNumber })} />
    <ContactModal open={contactModalOpen} setOpen={setContactModalOpen} routing="debt" /> 
    <Text size={26} bold>My Credit Report</Text>
    <p />
    <CreditWorthinessFactors creditRecord={useData} income={useData.income}/>
    <RowSpacer height={8}/>
    <FinancialHealthGraphs financialHealthData={financialHealthData} income={financialHealthData.income}/>
    {/* <Text size={16} bold>Credit Worthiness Factors</Text>
    <p />
    <MokuNotePanel {...getMokuTipProps(useData)} transparent />
    <p />
    <CardRow>
      <Card>
        <Text bold small>Credit Score</Text>
        <GraphContainer small>
          <PieGraph min={-350} max={750} {...riskSummmary} />
        </GraphContainer>
      </Card>
      <Card>
        <Text bold small>My Affordability</Text>
        <GraphContainer small>
          <PieGraph value={useData.affordability} min={Math.min(useData.affordability, -1000)} max={Math.max(useData.affordability, useData.income * 0.4)} {...affordabilitySummary} />
        </GraphContainer>
      </Card>
    </CardRow> */}
    <p />
    <Text size={16} bold>My Debt Repayments Summary</Text>
    <p />
    <SummaryItemsRow>
      <SummaryItemCard title="My Monthly Debt" description="Total debt repayments" value={printCurrency(useData.allDebt)} alert color="alertRed" />
      <SummaryItemCard title="Total Interest" description="Total for debt repayments" value={printCurrency(useData.totalInterest)} alert color="alertRed" />
      <SummaryItemCard title="My Debt Paid off by" description="Last month of debt repayments" value={getMaxDate(useData.debtPaymentProjection)} />
    </SummaryItemsRow>
    <p />
    <Text size={8} color="grey3">*Amounts displayed are estimations - complete your budget for an accrurate refelction.</Text>
    <p />
    <Text size={16} bold>Pay-off your Debt Quicker with Meerkat</Text>
    <p />
    <ToggleProductOption title="Debt Counselling" alert showSwitch expanded={withDC} setExpanded={!useData.hasDebtCounselling ? setWithDC : () => {}}>
      <>
        {!useData.affordability < 0 && !useData.hasDebtCounselling && <OpenOptionContainer>
          <Text><Text span color="alertRed">Alert!</Text> You are <Text span bold>OVER-INDEBTED</Text>. We might be able to reduce yout debt repayments by up to 50%.</Text>
        </OpenOptionContainer>}
        {useData.hasDebtCounselling && <OpenOptionContainer>
          <Text><Text span color="primary">Alert!</Text> You were <Text span bold>OVER-INDEBTED</Text>. We have been able to reduce your debt repayments.</Text>
        </OpenOptionContainer>}
        <OpenOptionContainer>
          <InformationRow title="Monthly Repayments" value ={printCurrency(useData.withDCPaymentProjection.payment)} />
          <InformationRow title="Interest Saved" value ={printCurrency(useData.withDCPaymentProjection.interestSaved)} />
          <InformationRow title="Maximum Debt Review Term" value ={`${useData.withDCPaymentProjection.payments.length} months`} />
          <InformationRow title="Paid Off By" value = {getMaxDate(useData.withDCPaymentProjection.payments)} valueColor="primary" />
          <p />
          {!useData.hasDebtCounselling && <center><Button onClick={() => setContactModalOpen(true)}>Ask about Debt Counselling</Button></center>}
        </OpenOptionContainer>
      </>
    </ToggleProductOption>
    <ToggleProductOption title="Snowball"  showSwitch expanded={withSnowball} setExpanded={setWithSnowball}>
    <OpenOptionContainer>
          <Text>Settle your debt quicker with an additional monthly amount</Text>
        </OpenOptionContainer>
        <OpenOptionContainer>
          
          <Slider label="Recommended snowball amount" minValue={150} maxValue={10000} value={snowballAmount} step={1} onChange={setSnowballAmount} />
          <p />
          <Query query={MY_SNOWBALL_PROJECTION} variables={{ amount: snowballAmount, withDC }} fetchPolicy="network-only" onCompleted={(data) => setSnowballProjection(data.getSnowballProjection)}>
            {({ data, loading }) => (
             loading ? <center><ActivityIndicator /></center> : <>
              <InformationRow title="Monthly Repayments" value ={printCurrency(data.getSnowballProjection.payment)} />
              <InformationRow title="Interest Saved" value ={printCurrency(data.getSnowballProjection.interestSaved)} />
              <InformationRow title="Paid Off By" value = {getMaxDate(data.getSnowballProjection.payments)} valueColor="primary" />
              </>
            )}
          </Query>
          
          <p />
          {/*<center><Button>Ask about Snowball</Button></center>*/}
        </OpenOptionContainer>
    </ToggleProductOption>
    <p />
    {/*<Text size={16} bold>Save Money</Text>
    <p />*/}
    <Text size={16} bold>How Meerkat can help your credit worthiness?</Text>
    <p />
    <Text size={13}>Here is how we at Meerkat will help you on your journey to financial wellbeing.</Text>
    <p />
    <GetInfoCard title="Savings" description="We all know the importance of saving. We are here to help you set up realistic goals." image={savings} onClick={() => window.location = "https://www.meerkat.co.za/savings"}/>
    <GetInfoCard title="Credit Life Insurance" description="Plan ahead and provide your family with peace of mind." image={funeral} onClick={() => window.location = "https://www.meerkat.co.za/credit-life"} />
    <GetInfoCard title="Funeral Cover" description="Discover how to stay ahead of your expenses and financial needs by planning for your finances today." image={creditLife} onClick={() => window.location = "https://www.meerkat.co.za/funeral"} />
    <p />
    {/*<center><Button outlined inverted onClick={() => setEmailModal(true)}>Email My report</Button></center>*/}
    <p />
  </MyCreditReportContainer>
}

export default MyCreditReport
